.customShadow{
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.userTable{
    @apply bg-white 
}
.userTable th{
    @apply border text-base font-medium
}

.userTable td{
    @apply border text-base text-center
}

/* scroll bar hiden*/
.scrollbar-none::-webkit-scrollbar{
    display: none;
}
@keyframes marquee {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  
  .animate-marquee {
    display: inline-block;
    white-space: nowrap;
    animation: marquee 15s linear infinite;
  }
  